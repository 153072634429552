<template>
  <div class="mine">
    <!-- 头像卡片 -->
    <div class="mine-card">
      <van-row>
        <van-col span="6">
          <van-image
		  v-if="user.avatar"
              fit="cover"
              round
              width="4.2rem"
              height="4.2rem"
              :src="imageUrl + user.avatar"/>
			  <van-image
			  v-else
			      fit="cover"
			      round
			      width="4.2rem"
			      height="4.2rem" 
			      src=""/>
        </van-col>  
        <van-col span="15">
          <div v-if="JSON.stringify(user) == '{}'" class="mine-card-title" @click="toUrl('/login',{ redirect })">未登录</div>
          <div v-else class="mine-card-title">{{ user.tel }}</div>
        </van-col>
        <van-col span="3">
          <van-icon name="arrow" class="mine-card-setting" @click="setting(user.id,user.avatar)" />
        </van-col>
      </van-row>
    </div>
    <!-- 操作栏 -->
    <div class="mine-setting">
      <van-cell-group>
		  <!-- <van-icon name="records" /> -->
		<!-- <van-cell v-if="user.sid > 0"  icon="refund-o" title="优惠券" @click="toUrl('/Discount')" is-link/> -->
		<!-- <van-cell v-if="user.sid > 0"  icon="records" title="编辑门店" @click="editShop(user.id)" is-link/> -->
        <!-- <van-cell v-if="user.sid > 0" icon="setting-o" title="营业设置" @click="toUrl('/business')" is-link/> -->
		<!-- <van-cell v-if="user.sid > 0" icon="clock-o" title="本店预约时间段" @click="toUrl('/appoitTime')" is-link/> -->
		<!-- <van-cell v-if="user.sid > 0" icon="description" title="本店配置文件管理" @click="configFiles(user.sid,user.mid)" is-link/> -->
        <van-cell v-if="type !== 1" icon="exchange" title="切换登录" @click="qiehuan" is-link/>
        <van-cell icon="setting-o" title="修改密码" @click="modifyPwd" is-link/>
        <van-cell icon="share-o" title="退出登录" @click="quitLogin" is-link/>
      </van-cell-group>
    </div>
    <van-popup v-model:show="pass" style="border-radius: 10px;">
      <van-form @submit="onSubmit" class="pass-form">
        <van-field
            label="原密码"
            label-width="5rem"
            size="large"
            clearable
            v-model="old_password"
            name="old_password"
            placeholder="请输入密码"
            :rules="[{ required:true, message: '请输入密码' }]"
        />
        <van-field
            label="新密码"
            size="large"
            label-width="5rem"
            clearable
            v-model="new_password"
            name="new_password"
            placeholder="请输入账号"
            :rules="[{ required:true, message: '请输入账号' }]"
        />
        <div style="margin-top:2rem;">
          <van-button round block type="primary" native-type="submit">提交修改</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { info, modifyPassword } from '@/api/user'
import { judge } from '@/api/merchant'
import { toUrl } from '@/utils/tools'
import {Toast,Dialog} from "vant";
import
	http
	from '../../utils/config.js'
export default {
  data() {
    return {
      type:1,
      pass: false,
      user: {},
      redirect: window.location.href,
      old_password: '',
      new_password: '',
	    imageUrl:http.imageUrl,
    }
  },
  mounted() {
    this.load()
    judge().then((res) => {
      this.type = res.data.type
      console.log(this.type)
    })
  },
  methods: {
    load() {
      info().then((res) => {
		  // console.log(res)
        this.user = res.data.user
      })
    },
	
    toUrl(path, param = null) {
      toUrl(path,param)
    },
	setting(id,image){
		// console.log(image)
		this.$router.push({
			path: '/setting',
			query: {
				id: id,
				image:image
			}
		})
	},
	configFiles(mid,sid){
		// console.log(mid,sid)
		this.$router.push({
			path: '/configFiles',
			query: {
				sid: sid,
				mid:mid
			}
		})
	},
	editShop(id){
		this.$router.push({
			path: '/editShop',
			query: {
				id: id
			}
		})
	},
    modifyPwd() {
      this.pass = !this.pass
    },
    qiehuan(){
      Dialog.alert({
        closeOnClickOverlay:true,
        message: '是否切换登录',
      }).then(() => {
        this.$router.push('/choice')

      });
    },
    onSubmit() {
      if (this.old_password === this.new_password) Toast.fail('新旧密码不能一样');
      modifyPassword({'new_password':this.new_password,'old_password':this.old_password}).then((res)=>{
        Toast({
          message:res.msg,
        });
        this.$store.commit('SET_TOKEN', "")
        this.$store.commit('SET_IS_LOGIN', false)
        this.$router.push('/login')
      })
    },
    quitLogin() {
      Dialog.alert({
        closeOnClickOverlay:true,
        message: '退出登录',
      }).then(() => {
        window.localStorage.clear()
        this.$store.commit('SET_TOKEN', "")
        this.$store.commit('SET_IS_LOGIN', false)
        this.$router.push('/login')
      });
    },
  }
}
</script>
<style lang="less">
.mine {
  min-height: calc(100vh);
  .mine-card {
    background: #ffffff;
    padding: 30px 15px;
  }
  .mine-setting {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .mine-card-title {
    line-height: 70px;
  }
  .mine-card-setting {
    line-height: 70px;
    float: right;
    font-size: 18px;
  }
  .pass-form {
    width: 18rem;
    padding: 2rem 1rem;
  }
}
</style>
